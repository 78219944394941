import { Each, roundNumber, useUI, useStyling } from "shiftly-ui";

import styles from "./ShiftDetails.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCheck } from "@fortawesome/pro-solid-svg-icons";

import {
  faClock,
  faCoffee,
  faDollar,
  faMoneyBill,
} from "@fortawesome/pro-regular-svg-icons";

const ShiftDetailsShiftInfo = ({
  position = {},
  location = {},
  address = {},
  prettyDate = "",
  hourlyRate = 0,
  day_of_week,
  duration,
  modifier,
  minimise = false,
  breakTime = "",
  shiftPeriod,
  timezone,
  user,
  shiftTotal,
  subtotal,
}) => {
  const { businessMode } = useUI();

  const styling = useStyling(styles);

  return (
    <>
      <div className={styling("title-container")}>
        {businessMode ? (
          <>
            <h4>
              {user?.first_name} {user?.last_name}
            </h4>
            <p>{position?.name}</p>
          </>
        ) : (
          <>
            <h4>
              {position?.name}, {location?.name}
            </h4>
            <p>
              {address?.city},{address?.state}
            </p>
          </>
        )}
      </div>
      <div className={styling("shift-details")}>
        <div className={styling("clean-format-info")}>
          <h5>
            {day_of_week} {prettyDate}
          </h5>
          <p>
            <span>
              {shiftPeriod} - {timezone}
            </span>
          </p>
          <p>{address.full_address}</p>
        </div>
        <div className={styling("info-block")}>
          <div className={styling("info-block-content")}>
            <FontAwesomeIcon
              icon={faMoneyBill}
              className={styling("info-block-icon")}
            />
            <div className={styling("info-block-detail")}>
              <p className={styling("info-block-title")}>Hourly Rate:</p>
              <p>${roundNumber(hourlyRate + modifier)}</p>
            </div>
          </div>
        </div>
        <div className={styling("info-block")}>
          <div className={styling("info-block-content")}>
            <FontAwesomeIcon
              icon={faClock}
              className={styling("info-block-icon")}
            />
            <div className={styling("info-block-detail")}>
              <p className={styling("info-block-title")}>Duration:</p>
              <p>{roundNumber(duration)} hrs</p>
            </div>
          </div>
        </div>
        {breakTime && (
          <div className={styling("info-block")}>
            {breakTime.split(",").map((breakTime, index) => (
              <div key={index} className={styling("info-block-content")}>
                <FontAwesomeIcon
                  icon={faCoffee}
                  className={styling("info-block-icon")}
                />
                <div className={styling("info-block-detail")}>
                  <p className={styling("info-block-title")}>Break:</p>
                  <p>{breakTime}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className={styling("info-block")}>
          <div className={styling("info-block-content")}>
            <FontAwesomeIcon
              icon={faDollar}
              className={styling("info-block-icon")}
            />
            <div className={styling("info-block-detail")}>
              <p className={styling("info-block-title")}>
                Total: ${roundNumber(businessMode ? shiftTotal : subtotal)}
              </p>
            </div>
          </div>
        </div>
        {!minimise && (
          <div className={styling("qualifications")}>
            <Each
              of={position?.qualifications}
              render={(qualification, index) => (
                <div key={index} className={styling("qualification")}>
                  <FontAwesomeIcon icon={faCheck} />
                  <p>{qualification?.description}</p>
                </div>
              )}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ShiftDetailsShiftInfo;
