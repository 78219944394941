import {
  Overlay,
  useAuth,
  FooterControl,
  useInhibitScroll,
  useFetch,
  useUI,
  PromptNotifications,
  usePushNotifications,
  Button,
} from "shiftly-ui";

import styles from "./ShiftDetails.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import clsx from "clsx";

import { faHeart, faMessageLines } from "@fortawesome/pro-regular-svg-icons";

import { useCallback, useEffect, useState } from "react";

import useCardSwipe from "../../hooks/useCardSwipe";

import { useNavigate } from "react-router-dom";

import ShiftDetailsHeader from "./ShiftDetailsHeader";

import ShiftDetailsShiftInfo from "./ShiftDetailsShiftInfo";

import ShiftDetailsPosition from "./ShiftDetailsPosition";

import ShiftDetailsLocation from "./ShiftDetailsLocation";

import ShiftDetailsMoreShifts from "./ShiftDetailsMoreShift";

import ShiftDetailsContactEmployer from "./ShiftDetailsContactEmployer";

import ShiftDetailsYourProfile from "./ShiftDetailsYourProfile";

import ShiftDetailsFooterNavigation from "./ShiftDetailsFooterNavigation";

import ShiftConfirm from "./ShiftConfirm";

import JSConfetti from "js-confetti";

import useShifts from "src/hooks/useShifts";

import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";

import ShiftCancel from "./ShiftCancel";

import useOnboardingComplete from "src/hooks/useOnboardingComplete";

import OnboardingIncomplete from "./alerts/OnboardingIncomplete";

const jsConfetti = new JSConfetti();

const ShiftDetails = ({
  showDetails: shift,
  setShowDetails,
  minimised = false,
}) => {
  const { user } = useAuth();

  const { actionShift } = useCardSwipe();

  const [confirmShift, setConfirmShift] = useState(false);

  const [animateHideDetails, setAnimateHideDetails] = useState(false);

  const [showNotificationPrompt, setShowNotificationPrompt] = useState(false);

  const [showOnboardingAlert, setShowOnboardingAlert] = useState(false);

  const { updateSubscription } = usePushNotifications(user?._id);

  const { settings, businessMode } = useUI();

  const [showCancel, setShowCancel] = useState(false);

  const { setFilteredShifts, filteredShifts } = useShifts();

  const navigate = useNavigate();

  const scrollingDivRef = useInhibitScroll();

  const { status } = shift;

  const {
    post: applyToShift,
    isLoading,
    refresh,
  } = useFetch({
    options: {
      onSuccess: async ({ warnings }) => {
        setFilteredShifts(filteredShifts.filter((s) => s._id !== shift._id));

        hideDetails();

        jsConfetti.addConfetti();
      },
    },
  });

  useEffect(() => {
    if (scrollingDivRef.current === null) return;

    scrollingDivRef.current.scrollTop = 0;

    setConfirmShift(false);
  }, [shift, scrollingDivRef]);

  const hideDetails = useCallback(() => {
    setAnimateHideDetails(true);

    setTimeout(() => {
      setAnimateHideDetails(false);

      setShowDetails(false);
    }, [500]);
  }, [setShowDetails]);

  const { todo } = useOnboardingComplete();

  const handleApplyToShift = useCallback(async () => {
    if (!todo?.total) {
      setShowOnboardingAlert(true);

      return;
    }

    if (!settings.push_notifications_prompted && !confirmShift) {
      setShowNotificationPrompt(true);
    } else {
      const sub = await updateSubscription();

      if (!sub) setShowNotificationPrompt(true);
    }

    if (!confirmShift) {
      setConfirmShift(true);

      return;
    }

    applyToShift({
      entity: "ShiftApplication",
      method: "applyToShift",
      data: {
        shift,
        user,
      },
    });
  }, [
    user,
    shift,
    confirmShift,
    applyToShift,
    settings,
    updateSubscription,
    todo,
  ]);

  const saveShift = useCallback(() => {
    actionShift(shift, "saved");

    refresh("Shifts.FetchFilteredShifts");

    hideDetails();
  }, [actionShift, shift, hideDetails, refresh]);

  const messageEmployer = useCallback(() => {
    navigate("/inbox?location=" + shift.location?._id);
  }, [shift, navigate]);

  const close = useCallback(() => {
    setShowDetails(false);
  }, [setShowDetails]);

  return (
    <>
      <ShiftCancel
        shift={shift}
        setShow={setShowCancel}
        show={showCancel}
        setShowDetails={setShowDetails}
      />
      <PromptNotifications
        show={showNotificationPrompt}
        setShow={setShowNotificationPrompt}
      />
      <OnboardingIncomplete
        shift={shift}
        show={showOnboardingAlert}
        setShow={setShowOnboardingAlert}
      ></OnboardingIncomplete>
      <Overlay
        open={!!shift && !animateHideDetails}
        setOpen={setShowDetails}
        className={styles["overlay"]}
      />
      <div
        className={clsx(
          styles["container"],
          !!shift && styles["visible"],
          animateHideDetails && styles["animate-hide"],
        )}
      >
        <div className={styles["wrapper"]}>
          <ShiftDetailsHeader
            {...shift}
            logo={shift?.location?.logo}
            setShowDetails={setShowDetails}
            confirmShift={confirmShift}
            setConfirmShift={setConfirmShift}
          />
          <ShiftConfirm
            {...shift}
            confirmShift={confirmShift}
            logo={shift?.location?.logo}
            key={"shiftConfirm" + shift?._id}
          />
          <div
            className={clsx(
              styles["scroll-container"],
              confirmShift && styles["confirming"],
            )}
            ref={scrollingDivRef}
          >
            <ShiftDetailsShiftInfo
              {...shift}
              key={"shiftInfo" + shift?._id}
              minimise={minimised}
            />
            <ShiftDetailsPosition
              {...shift}
              key={"shiftPosition" + shift?._id}
            />
            {!minimised && (
              <ShiftDetailsLocation
                {...shift}
                logo={shift?.location?.logo}
                key={"locationDetails" + shift?._id}
                shift={shift}
              />
            )}
            {!minimised && (
              <ShiftDetailsMoreShifts
                {...shift}
                key={"moreShifts" + shift?._id}
                setShowDetails={setShowDetails}
              />
            )}
            {!businessMode && (
              <ShiftDetailsContactEmployer
                {...shift}
                logo={shift?.location?.logo}
              />
            )}
            {!minimised && <ShiftDetailsYourProfile />}
            {status === "confirmed" && (
              <div className={styles["cancel-shift"]}>
                <h4>Need to cancel?</h4>
                <p>Click 'Cancel' below and specify your reason.</p>
                <Button
                  theme={"secondary-outline"}
                  onClick={() => setShowCancel(true)}
                  className={styles["cancel-btn"]}
                >
                  Cancel Shift
                </Button>
              </div>
            )}
            <ShiftDetailsFooterNavigation />
          </div>
          <FooterControl
            onNextClick={status === "confirmed" ? () => {} : saveShift}
            onPrevClick={messageEmployer}
            buttonText={
              status === "confirmed"
                ? "Close"
                : !confirmShift
                  ? "Apply to Shift"
                  : isLoading
                    ? "Loading..."
                    : "Confirm"
            }
            onButtonClick={status === "confirmed" ? close : handleApplyToShift}
            nextButton={
              status === "confirmed" ? (
                <FontAwesomeIcon icon={faEllipsis} />
              ) : (
                <FontAwesomeIcon icon={faHeart} />
              )
            }
            prevButton={<FontAwesomeIcon icon={faMessageLines} />}
            buttonProps={{ disabled: isLoading }}
          />
        </div>
      </div>
    </>
  );
};

export default ShiftDetails;
