import styles from "./ProfileHome.module.css";

import ProfileFinishSetup from "./components/ProfileFinishSetup";

import ProfileHomeBackdrop from "./components/ProfileHomeBackdrop";

import ProfileHomeNavigation from "./components/ProfileHomeNavigation";

import ProfileHomeCardMain from "./components/ProfileHomeCardMain";

const ProfileHome = () => {
  return (
    <div className={styles["container"]}>
      <ProfileHomeBackdrop />
      <div className={styles["content"]}>
        <ProfileHomeCardMain />
        <ProfileFinishSetup />
        <ProfileHomeNavigation />
      </div>
    </div>
  );
};

export default ProfileHome;
