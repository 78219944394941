import { useAuth, formatDateToPrettyTime, useFetch } from "shiftly-ui";

import styles from "./MessageBubbleShifter.module.css";

import clsx from "clsx";

import useShiftlyLocation from "src/pages/inbox/hooks/useShiftlyLocation";

import ShiftCard from "src/pages/shifts/components/ShiftCard";

const MessageBubbleShifter = ({
  content,
  for_id,
  sent_date,
  after,
  type = "text",
  setShowShiftDetails = () => {},
}) => {
  const { user } = useAuth();

  const { activeLocation } = useShiftlyLocation();

  const forMe = user._id === for_id || activeLocation?._id === for_id;

  const showTime =
    !after ||
    new Date(after.sent_date).getTime() - new Date(sent_date).getTime() >
      300000;

  const {
    data: [shift],
  } = useFetch({
    request: {
      entity: "Shift",
      method: "getWithCalculatedFields",
      criteria: {
        _id: content,
      },
    },
    dependency: content,
    options: {
      enabled: type === "shift",
      select: (data) => {
        return data.map((s) => ({ ...s, address: s.location?.address }));
      },
    },
  });

  return (
    <div className={styles["container"]}>
      {type === "text" && (
        <div
          className={clsx(
            styles["bubble"],
            forMe ? styles["receiving"] : styles["sending"],
          )}
        >
          <p className={styles["text"]}>{content}</p>
        </div>
      )}
      {type === "shift" && (
        <div className={styles["shift-container"]}>
          <ShiftCard shift={shift} setShowDetails={setShowShiftDetails} />
        </div>
      )}
      {showTime && (
        <p
          className={clsx(
            styles["sent_time"],
            forMe ? styles["left"] : styles["right"],
          )}
        >
          {formatDateToPrettyTime(sent_date)}
        </p>
      )}
    </div>
  );
};

export default MessageBubbleShifter;
