import {
  SideLayout,
  useStyling,
  useProfile,
  FormV2,
  Button,
  Input,
  TextArea,
  newGUID,
  FileUploader,
} from "shiftly-ui";

import styles from "./CVEdit.module.css";

import { useCallback, useMemo } from "react";

import { faSave, faFileUpload } from "@fortawesome/pro-regular-svg-icons";

const CVEditQualification = ({
  show,
  setShow = () => {},
  record = {},
  mode = "Add",
}) => {
  const styling = useStyling(styles);

  const { profile, updateProfile, updatingProfile, isError } = useProfile();

  const initial = useMemo(() => {
    if (show && mode === "Add")
      return {
        name: "",
        issuing_body: "",
        issue_date: "",
        description: "",
      };

    if (record.id) return record;
  }, [record, show, mode]);

  const handleSubmit = useCallback(
    async (data) => {
      const { qualifications } = structuredClone(profile);

      if (record.id) {
        const index = qualifications.findIndex((exp) => exp.id === record.id);

        qualifications[index] = { ...qualifications[index], ...data };
      } else {
        qualifications.push({ ...data, id: newGUID() });
      }

      const response = await updateProfile({ data: { qualifications } });

      !response.error && setShow(false);

      return response;
    },
    [profile, updateProfile, record, setShow],
  );

  return (
    <SideLayout heading={mode + " Qualification"} show={show} setShow={setShow}>
      <FileUploader visible={false} name={"work_experience_file"} />
      <FormV2 initial={initial} onSubmit={handleSubmit}>
        <div className={styling("container")}>
          <Input
            name={"name"}
            label={"Name of certification"}
            placeholder={"RSA Licence"}
            required
          />
          <Input
            name={"issuing_body"}
            label={"Issuing Authority"}
            placeholder={"NSW Government"}
            required
          />
          <Input
            type="date"
            name={"issue_date"}
            label={"When did you receive this certification"}
            required
            validate={(v) => {
              if (!v) return false;

              return new Date(v) < new Date()
                ? true
                : "Date cannot be in the future";
            }}
          />
          <TextArea
            name={"description"}
            label={"What did you do"}
            required
            placeholder={"Describe some of your duties and skills here"}
          />
          <Button
            theme={"secondary-transparent"}
            icon={faFileUpload}
            iconSide="left"
            className={styling("upload-btn")}
          >
            Upload document
          </Button>
          <Button
            loading={updatingProfile}
            error={isError}
            theme={"secondary"}
            type="submit"
            className={styling("save-btn")}
            icon={faSave}
          >
            Save Qualification
          </Button>
          <Button
            theme={"secondary-transparent"}
            className={styling("save-btn")}
            onClick={() => {
              setShow(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </FormV2>
    </SideLayout>
  );
};

export default CVEditQualification;
