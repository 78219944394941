import { createContext, useEffect, useState, useContext } from "react";

export const FormContext = createContext({
  data: {},
  setData: () => {},
  validateFields: {},
});
export const useForm = () => useContext(FormContext);

export const FormContextProvider = ({ children, setForm, initial }) => {
  const [data, setData] = useState(initial || {});

  const [validateFields, setValidateFields] = useState({});

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setData(initial || {});
  }, [initial]);

  useEffect(() => {
    const validate = Object.values(validateFields).every((item) => item);

    setForm((prev) => ({ ...prev, isValid: validate }));

    setIsValid(validate);
  }, [validateFields, setForm]);

  useEffect(() => setForm((prev) => ({ ...prev, data })), [data, setForm]);

  return (
    <FormContext.Provider
      value={{ data, setData, isValid, validateFields, setValidateFields }}
    >
      {children}
    </FormContext.Provider>
  );
};
