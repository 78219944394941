import { Each } from "shiftly-ui";

import styles from "./ShiftDetails.module.css";

import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/pro-duotone-svg-icons";

const footerItems = [
  {
    text: "Terms & Conditions",
    link: "/tncs",
  },
  {
    text: "Contact App Support",
    link: "/contact",
  },
  { text: "Go to Saved Shifts", link: "/saved" },
];

const ShiftDetailsFooterNavigation = () => {
  const navigate = useNavigate();

  return (
    <div className={styles["footer-container"]}>
      <Each
        of={footerItems}
        render={(item, index) => (
          <div
            className={styles["footer-item"]}
            key={index}
            onClick={() => navigate(item.link)}
          >
            <p>{item.text}</p>
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        )}
      />
    </div>
  );
};

export default ShiftDetailsFooterNavigation;
