import { useAlerts, Button, useStyling } from "shiftly-ui";

import {
  faLock,
  faUser,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";

import styles from "../ProfileHome.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useNavigate } from "react-router-dom";

import { faInfoCircle, faTrash } from "@fortawesome/pro-regular-svg-icons";

import { useMemo } from "react";

import useOnboardingComplete from "src/hooks/useOnboardingComplete";

const ProfileHomeNavigation = () => {
  const { confirm } = useAlerts();

  const styling = useStyling(styles);

  const { todo } = useOnboardingComplete();

  const bottomLinks = useMemo(() => {
    return [
      {
        text: "Personal & Employment Details",
        link: "/employment-details",
        icon: faUser,
        badge: !todo?.personalDetails,
      },
      { text: "Login & Security", link: "/security", icon: faLock },
      { text: "Trashed Shifts", link: "/trashed-shifts", icon: faTrash },
      {
        text: "Help & Support",
        link: "/contact",
        icon: faInfoCircle,
      },
    ];
  }, [todo]);

  return (
    <div className={styling("profile-nav-container")}>
      <h4>Account Settings</h4>
      <div className={styling("profile-nav-bottom")}>
        {bottomLinks.map((link) => (
          <NavCardBottom
            key={link.text}
            text={link.text}
            link={link.link}
            icon={link.icon}
            badge={link.badge}
          />
        ))}
      </div>
      <div className={styling("logout-container")}>
        <Button
          theme={"secondary-outline"}
          className={styling("logout-btn")}
          onClick={async () => {
            if (
              !(await confirm({
                label: "Log Out",
                text: "Are you sure you want to log out?",
                confirmText: "Log Out",
                cancelText: "Cancel",
                inverse: true,
              }))
            )
              return;

            localStorage.removeItem("shiftly-auth-token");

            window.location.href = "/portal";
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

const NavCardBottom = ({ text, icon, link, badge }) => {
  const navigate = useNavigate();

  const styling = useStyling(styles);

  return (
    <div
      className={styling("profile-nav-card-bottom")}
      onClick={() => navigate(link)}
    >
      <div className={styling("profile-nav-bottom-details")}>
        <FontAwesomeIcon icon={icon} />
        <p>{text}</p>
      </div>
      {badge && <div className={styling("profile-nav-card-badge")}></div>}
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
};

export default ProfileHomeNavigation;
