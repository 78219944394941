import { useCallback, useEffect, useMemo } from "react";

import { useForm } from "src/contexts/Form";

import { useUncontrolled } from "uncontrollable";

const useValidation = (props) => {
  const {
    name = "",
    validation = () => true,
    mandatory = false,
    onChange: onChangeProp = () => {},
    value,
    setValue,
    touched,
    setTouched,
    error,
    setError,
  } = useUncontrolled(props, {
    value: "setValue",
    touched: "setTouched",
    error: "setError",
  });

  const { data, setData, setValidateFields = () => {} } = useForm();

  //Effect that determines whether an input field requires validation
  useEffect(() => {
    if (value) {
      setValidateFields((prev) => ({ ...prev, [name]: validation(value) }));
    } else {
      setValidateFields((prev) => ({ ...prev, [name]: !mandatory }));
    }

    setError(false);

    setTouched(false);

    return () =>
      setValidateFields((prev) => {
        const { [name]: _, ...rest } = prev;

        return rest;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (data?.[name] === undefined && Object.keys(data).length === 0) return;

    setValue(data?.[name]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.[name], setValue, name]);

  //Validate the entered value of the input field against a provided function
  useEffect(() => {
    if (touched && data[name] !== undefined) {
      setValidateFields((prev) => ({
        ...prev,
        [name]: validation(data[name]),
      }));

      let timeout = setTimeout(() => {
        if (!validation(data[name])) {
          setError(true);
        }
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data[name], touched, value]);

  //Updates relevants states on input change
  const onChange = useCallback(
    (val) => {
      setTouched(true);

      setError(false);

      name && setData((prev) => ({ ...prev, [name]: val }));

      onChangeProp(val);

      setValue(val);
    },
    [name, setData, setTouched, onChangeProp, setError, setValue],
  );

  return useMemo(
    () => ({
      onChange,
      error,
      setError,
      touched,
      setTouched,
      data,
      setData,
      value,
      setValue,
    }),
    [
      error,
      setTouched,
      value,
      onChange,
      data,
      setData,
      touched,
      setError,
      setValue,
    ],
  );
};

export default useValidation;
