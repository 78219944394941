import { Button, Input, useAuth, FormV2 } from "shiftly-ui";

import styles from "./Portal.module.css";

import { useCallback } from "react";

import { motion } from "framer-motion";

import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const { signup, signupIsLoading: isLoading } = useAuth();

  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (data) => {
      return await signup(data, "shifter");
    },
    [signup],
  );

  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={styles["content-container"]}
    >
      <h2 className={styles["heading"]}>
        Welcome to <span>Shiftly</span>
      </h2>
      <p className={styles["subtext"]}>
        Create an account to access more with Shiftly.
      </p>
      <FormV2 onSubmit={onSubmit} className={styles["form-container"]}>
        <div className={styles["content"]}>
          <Input name={"first_name"} />
          <Input name={"last_name"} />
          <Input name={"date_of_birth"} />
          <Input name={"email"} />
          <Input name={"password"} />
          <Input name={"confirm_password"} submitOnEnter />
        </div>
        <div className={styles["button-container"]}>
          <Button
            type={"submit"}
            theme={"secondary"}
            loading={isLoading}
            className={styles["button"]}
          >
            Signup
          </Button>
          <p className={styles["nav-text"]}>
            Already have an account?{" "}
            <span onClick={() => navigate("/portal?page=login")}>Login</span>
          </p>
        </div>
      </FormV2>
    </motion.div>
  );
};

export default SignUp;
