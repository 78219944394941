import { SideLayout, useStyling, Button, Input, useFetch, useAuth, Dropdown, FormV2 } from "shiftly-ui";
import styles from "../EmploymentDetails.module.css";
import { useCallback } from "react";
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import useOnboardingComplete from "src/hooks/useOnboardingComplete";

const EDSuperDetails = (props) => {
  const styling = useStyling(styles);

  const { user } = useAuth();

  const { superDetails } = useOnboardingComplete();

  const { post, isLoading, isError, updateCache } = useFetch({
    options: {
      onSuccess: (data) => updateCache("SuperMembership.GetSuperMembership", data),
    },
  });

  const { data: superFundsList } = useFetch({
    request: { entity: "SuperFund", method: "get" },
    options: {
      select: (data) => {
        return data.map((fund) => ({
          ...fund,
          label: fund["fund_name"] + " (" + fund.usi + ")",
          value: fund["_id"],
        }));
      },
    },
  });

  const handleSubmit = useCallback(
    async (data) => {
      return await post({
        entity: "SuperMembership",
        method: "update",
        criteria: { user: user._id },
        data: {
          ...data,
          user: user._id,
        },
        options: { upsert: true },
      });
    },
    [post, user]
  );

  return (
    <SideLayout heading={"Super Details"} {...props}>
      <div className={styling("form-container")}>
        <FormV2 onSubmit={handleSubmit} initial={superDetails}>
          <Dropdown options={superFundsList} name={"super_fund"} />
          <Input name={"member_number"} />
          <Button
            error={isError}
            loading={isLoading}
            theme={"secondary"}
            type="submit"
            className={styling("save-btn")}
            icon={faSave}
          >
            Save Super Details
          </Button>
          <Button
            theme={"secondary-transparent"}
            className={styling("save-btn")}
            onClick={() => {
              props.setShow(false);
            }}
          >
            Cancel
          </Button>
        </FormV2>
      </div>
    </SideLayout>
  );
};

export default EDSuperDetails;
