import { useStyling, Swiper } from "shiftly-ui";

import styles from "./ShiftDetails.module.css";

import clsx from "clsx";

const ShiftDetailsPosition = ({
  position,
  location,
  responsibilities = [],
  notes = "",
}) => {
  const styling = useStyling(styles);

  return (
    <div className={styling("position-details")}>
      <h6>Job Description</h6>
      <p>
        {position?.description || "No description provided for this position."}
      </p>
      <div className={styling("responsibilities")}>
        <h6>You might be expected to:</h6>
        <ul>
          {responsibilities.map((resp, index) => (
            <li key={index}>
              <p>{resp}</p>
            </li>
          ))}
        </ul>
      </div>
      {notes && (
        <div className={styling("notes")}>
          <h6>Manager's Notes:</h6>
          <p>{notes}</p>
        </div>
      )}
      {location?.images?.length > 0 ? (
        <div className={styling("location-images")}>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={0}
            slides={location.images.map((img, index) => (
              <div
                key={index}
                className={clsx(
                  styling("position-img"),
                  index === location.images.length - 1 && styling("last"),
                )}
              >
                <img src={img} alt="Location" />
              </div>
            ))}
          />
        </div>
      ) : (
        <p>No images available for this location.</p>
      )}
    </div>
  );
};

export default ShiftDetailsPosition;
