import { useFetch, useStyling, BottomLink, CentralHeader } from "shiftly-ui";

import styles from "./PaymentsHome.module.css";

import { useEffect, useState } from "react";

import PayRunCard from "./components/PayRunCard";

import PayRunNotification from "./components/PayRunNotification";

const PaymentsHome = () => {
  const styling = useStyling(styles);

  const [currentPayPeriod, setCurrentPayPeriod] = useState();

  const [processingPeriod, setProcessingPeriod] = useState();

  const [lastPaidPeriod, setLastPaidPeriod] = useState();

  const { data: payruns } = useFetch({
    request: {
      entity: "PayRun",
      criteria: {
        start_date: {
          $lte: new Date(),
        },
      },
      options: {
        limit: 3,
        sort: {
          start_date: -1,
        },
      },
      id: "Payrun.CurrentPayruns",
    },
  });

  const { data: timesheets } = useFetch({
    request: {
      entity: "Timesheet",
      method: "get",
      populate: [
        {
          path: "shift",
          populate: ["location", "position"],
        },
      ],
      criteria: {
        payrun: currentPayPeriod?._id,
      },
      id: "Timesheet.GetTimesheetsForCurrentPayrun",
    },
    dependency: currentPayPeriod?._id,
  });

  useEffect(() => {
    let finishedProcessing = false;

    payruns.forEach((payrun, index) => {
      if (payrun.status === "current") {
        setCurrentPayPeriod(payrun);

        return;
      }

      if (payrun.status === "processing") {
        setProcessingPeriod(payrun);

        return;
      }

      if (payrun.status === "paid" && !finishedProcessing) {
        finishedProcessing = true;

        setLastPaidPeriod(payrun);

        return;
      }
    });
  }, [payruns]);

  return (
    <div className={styling("container")}>
      <CentralHeader text={"Payments"} />
      <div className={styles["content"]}>
        <div className={styling("current")}>
          <PayRunCard
            status={"Active"}
            payrun={currentPayPeriod}
            timesheets={timesheets}
          />
        </div>
        <div className={styling("processing")}>
          <PayRunCard status={"Processing"} payrun={processingPeriod} />
        </div>
      </div>
      <div className={styling("notification")}>
        <PayRunNotification payrun={lastPaidPeriod} />
      </div>
      <BottomLink
        links={[{ name: "Payment History", path: "/payment-history" }]}
      />
    </div>
  );
};

export default PaymentsHome;
