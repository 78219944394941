import {
  useInlineStyles,
  fullAddressValidator,
  useAsyncEffect,
} from "shiftly-ui";

import React, { useState, forwardRef, useCallback, useEffect } from "react";

import { Autocomplete, TextField } from "@mui/material";

import useValidation from "src/pages/search/components/search_bar/shift_filter/Filters/hooks/useValidation";

import styles from "./InputStyles.module.css";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronDown } from "@fortawesome/pro-light-svg-icons";

import { useMapsLibrary } from "@vis.gl/react-google-maps";

const AddressAutocomplete = forwardRef(
  (
    {
      fullAddress = true,
      label,
      placeholder,
      mandatory = false,
      message,
      disabled,
      inActive = false,
      className,
      initialValue = "",
      inputProps = {},
      ...props
    },
    ref,
  ) => {
    const { error, setError, setTouched, value, onChange } =
      useValidation(props);

    const style = useInlineStyles(props);

    const [inputValue, setInputValue] = useState(initialValue);

    const [options, setOptions] = useState([]);

    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const [autocompleteService, setAutocompleteService] = useState(null);

    const placesLib = useMapsLibrary("places");

    useEffect(() => {
      if (placesLib) {
        setAutocompleteService(new placesLib.AutocompleteService());
      }
    }, [placesLib]);

    const handleSelect = useCallback(
      (addr) => {
        var geocoder = new window.google.maps.Geocoder();

        geocoder.geocode(
          {
            address: addr?.description,
          },
          function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
              var latitude = results[0].geometry.location.lat();

              var longitude = results[0].geometry.location.lng();

              setInputValue(addr.description);

              onChange({
                street: addr.terms?.[0]?.value + " " + addr.terms?.[1]?.value,
                city: addr.terms?.[2]?.value,
                state: addr.terms?.[3]?.value,
                country: "Australia",
                full_address: addr.description,
                place_id: addr.place_id,
                latitude,
                longitude,
                pinpoint: {
                  type: "Point",
                  coordinates: [longitude, latitude],
                },
              });
            }
          },
        );
      },
      [onChange],
    );

    useAsyncEffect(async () => {
      if (initialValue) {
        const ops = await getPlacePredictions(initialValue);

        setOptions(ops);

        handleSelect(ops[0]);

        setInputValue(ops[0]?.description || initialValue);
      }
    }, [initialValue]);

    const getPlacePredictions = useCallback(
      async (inputValue) => {
        return new Promise((resolve) => {
          if (!window.google) {
            console.error("Google Maps JavaScript API library must be loaded.");

            return;
          }

          if (!autocompleteService) return;

          autocompleteService
            .getPlacePredictions(
              { input: inputValue, componentRestrictions: { country: "au" } },
              (results) => {
                resolve(results || []);
              },
            )
            .catch((err) => {
              console.error(err);
            });
        });
      },
      [autocompleteService],
    );

    useEffect(() => {
      if (value && value.full_address) {
        setInputValue(value.full_address);
      }
    }, [value]);

    return (
      <div className={clsx(styles["input-wrapper"], className)} style={style}>
        {label && (
          <label className={clsx(styles["label"], "small")}>{label}</label>
        )}
        <div className={styles["select-wrapper"]}>
          <Autocomplete
            open={isDropdownOpen}
            onOpen={() => setDropdownOpen(true)}
            onClose={() => setDropdownOpen(false)}
            inputValue={inputValue}
            onFocus={() => setTouched(true)}
            getOptionLabel={(option) =>
              option?.label || option?.description || option || ""
            }
            onInputChange={async (event, newInputValue) => {
              setTouched(true);

              if (event?.type !== "change") return;

              setInputValue(newInputValue);

              setOptions(await getPlacePredictions(inputValue));
            }}
            isOptionEqualToValue={(option, value) => {
              return option?.description === value?.description;
            }}
            options={options.map((option) => option.description)}
            onChange={(event, newValue) => {
              const addr = options.find(
                (option) => option.description === newValue,
              );

              if (fullAddress && fullAddressValidator(addr)) {
                handleSelect(addr);
              } else if (!fullAddress) {
                handleSelect(addr);
              } else {
                onChange(newValue);

                setError(true);
              }
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border:
                    "0.5px solid var(--secondary-400, #94a3b9) !important",
                  borderRadius: "7px !important",
                },
              },
            }}
            className={styles["multi-select-component"]}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={placeholder}
                className={clsx(styles["input"], {
                  [styles["disabled"]]: disabled || inActive,
                })}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className={clsx(
                          styles["chevron"],
                          isDropdownOpen && styles["chev-open"],
                        )}
                      />
                    </React.Fragment>
                  ),
                }}
                disabled={disabled || inActive}
              />
            )}
            {...inputProps}
            ref={ref}
          />
        </div>
        {error && (
          <div className={styles["error-container"]}>
            <p className={clsx(styles["message"], "small")}>{message}</p>
          </div>
        )}
      </div>
    );
  },
);

export default AddressAutocomplete;
