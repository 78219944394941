import { useInhibitScroll, useAuth, useFetch, roundNumber } from "shiftly-ui";

import clsx from "clsx";

import styles from "./ShiftDetails.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/pro-duotone-svg-icons";

import { useNavigate } from "react-router-dom";

import Skeleton from "react-loading-skeleton";

const ShiftConfirm = (props) => {
  const {
    logo,
    confirmShift = false,
    position = {},
    location = {},
    address = {},
    prettyDate,
    shiftPeriod,
    day_of_week,
    year,
    duration,
    hourlyRate,
    modifier,
    superAmount,
    lateNightBonus,
    shiftSubTotal,
    baseAmount,
    breakTime,
  } = props;

  const { user } = useAuth();

  const scrollingDivRef = useInhibitScroll();

  const {
    data: [bankAccount],
    isLoading: bankIsLoading,
  } = useFetch({
    request: { entity: "BankAccount", criteria: { user: user?._id } },
    dependency: user?._id,
    options: {
      enabled: confirmShift,
    },
  });

  const {
    data: [superDetails],
    isLoading: superIsLoading,
  } = useFetch({
    request: {
      entity: "SuperMembership",
      method: "get",
      populate: ["super_fund"],
      criteria: { user: user?._id },
    },
    dependency: user?._id,
    options: {
      enabled: confirmShift,
    },
  });

  return (
    <div
      className={clsx(
        styles["confirm-container"],
        confirmShift && styles["confirming-visible"],
      )}
      ref={scrollingDivRef}
    >
      <div className={styles["confirm-wrapper"]}>
        <div className={styles["confirm-heading"]}>
          <h2>Confirm shift details</h2>
          <p>Please confirm shift details before sending your application</p>
        </div>
        <div className={styles["confirm-overview"]}>
          <div className={styles["confirm-overview-heading"]}>
            <div className={styles["confirm-overview-logo"]}>
              <img src={logo} alt="logo" />
            </div>
            <div className={styles[""]}>
              <h5>{location?.name}</h5>
              <p className={styles["overview-address"]}>
                {address.full_address}
              </p>
            </div>
          </div>
          <div className={styles["confirm-position"]}>
            <h5>{position?.name}</h5>
          </div>
          <div className={styles["confirm-date"]}>
            <h5>
              {day_of_week}, {prettyDate} {year}
            </h5>
            <p>{shiftPeriod}</p>
            {breakTime && <p className={styles["break"]}>Break: {breakTime}</p>}
          </div>
        </div>
        <div className={styles["confirm-pay-container"]}>
          <div className={styles["confirm-amount-container"]}>
            <p>
              Award Rate{" "}
              <span>
                ({roundNumber(duration)} hours @ ${roundNumber(hourlyRate)})
              </span>
              :
            </p>
            <p>${roundNumber(baseAmount)}</p>
          </div>
          <div className={styles["confirm-amount-container"]}>
            <p>
              Employer Bonus{" "}
              <span>
                ({roundNumber(duration)} hours @ ${modifier})
              </span>
              :
            </p>
            <p>${roundNumber(modifier * duration)}</p>
          </div>
          <div className={styles["confirm-amount-container"]}>
            <p>Late Night Bonus :</p>
            <p>${roundNumber(lateNightBonus)}</p>
          </div>
          <div className={styles["confirm-amount-total"]}>
            <h5>
              Total Income <span>(Excl. Tax)</span>:
            </h5>
            <h4>${roundNumber(shiftSubTotal)}</h4>
          </div>
          <div className={styles["confirm-amount-container"]}>
            <p>Super Contribution:</p>
            <p>${roundNumber(superAmount)}</p>
          </div>
        </div>
        {bankIsLoading ? (
          <Skeleton height={"80px"} className={styles["skeleton-details"]} />
        ) : (
          <DetailCard
            title="Payment Details"
            main={bankAccount?.account_name}
            sub={"(" + bankAccount?.bsb + ") -  " + bankAccount?.account_number}
            link="/employment-details?key=bankDetails"
          />
        )}
        {superIsLoading ? (
          <Skeleton height="90px" className={styles["skeleton-details"]} />
        ) : (
          <DetailCard
            title="Superannuation"
            main={superDetails?.superFund?.fund_name}
            sub={"Member number: " + superDetails?.member_number}
            link="/employment-details?key=superDetails"
          />
        )}
      </div>
    </div>
  );
};

const DetailCard = ({ title = "", main = "", sub = "", link = "/profile" }) => {
  const navigate = useNavigate();

  return (
    <div className={styles["confirm-card"]} onClick={() => navigate(link)}>
      <FontAwesomeIcon
        className={styles["confirm-arrow-icon"]}
        icon={faArrowRight}
      />
      <p>{title}</p>
      <h5>{main}</h5>
      <p>{sub}</p>
    </div>
  );
};

export default ShiftConfirm;
